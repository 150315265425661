export const blogPosts = [
  {
    id: 1,
    title: "Lean SLO's",
    image: "/images/slo.jpg",
    content: `
### **Understanding Service Level Objectives Through a User-Centric Lens**

#### **The Conundrum of SLOs:**
- **From User's Perspective:** 
  As a principle full stack developer, it's intriguing to consider the alignment of engineering efforts with user-centric needs. Traditional SLOs often set performance benchmarks without deeply considering what truly matters to the end-user. This raises a fundamental question: why don't we start with a 100% service level for all services?

#### **Setting SLOs:**
- **Determining the Service Level:** 
  The challenge lies in deciding the appropriate level for SLOs. Should it be 0% or 100%? The idea of using the most critical dependent system as a yardstick is one approach, but it becomes complex when assessing an entire user interface.

#### **User-Driven SLOs:**
- **From Performance to User Experience:** 
  The shift in perspective involves defining the performance and health of a service from the user’s viewpoint. A system that is high-performing but underutilized is a wasted resource. This realization leads to two key questions: 
  - **Which Services to Define:** Identifying which services need specific service levels.
  - **Setting the Level:** Deciding the appropriate service level for each.

#### **Service Types in a User Context:**
- **Horizontal vs. Vertical Services:** 
  In a todo app, for instance, horizontal services include Client UI, UI Service, API Service, and Database. Vertical services are actions like reading, adding, completing, setting reminders, and deleting todos. Users tend to value vertical services more as they directly impact their experience. A fully functional API is meaningless if the Client UI is non-functional.

#### **Redefining Service Boundaries:**
- **Feature Area as Service Boundary:** 
  A good starting point is to define service boundaries around feature areas or common user tasks. This approach is more user-focused and convenient in the short term.

#### **Service Level Indicators (SLIs) for UI:**
- **Beyond Traditional Metrics:** 
  Traditional SLIs, like page load times or the number of 200 responses, don't fully capture user impact. A more effective metric is the ratio of good to valid events, providing a clearer picture of user experience.
  - **Defining Good vs. Valid Events:** A valid event might include a form loading without JavaScript errors, while invalid events could be issues like forms not loading content or unresponsive buttons.

#### **Service Levels for Seldom-Used Services:**
- **Balancing Importance and Usage:** 
  Rarely used but critical services, like a 'Deactivate User' feature, should have high SLOs since a single failure can significantly impact the overall service level. Conversely, less critical services might have more lenient SLOs.

#### **Conclusion:**
- **Redefining Success Metrics:** 
  This user-centric approach to SLOs allows for a more meaningful understanding of service levels. It moves away from striving for an arbitrary 100% target, allowing for more nuanced decisions based on user impact.
- **Future Directions:** 
  The ultimate goal might be to align SLOs with user journeys, creating a comprehensive view that integrates both horizontal and vertical service aspects. However, this remains a complex challenge to address in the future.
    `,
    author: "Phil Hauser",
    date: "2023-11-21",
  },
  {
    id: 2,
    title: "Zeno's Dichotomy and Lean Thinking",
    content: `
### Navigating Product Development: Lessons from Zeno’s Dichotomy Paradox

In the realm of product development, the journey towards innovation is often riddled with complexities and unexpected challenges. The ancient philosophical argument, Zeno’s Dichotomy Paradox, offers an intriguing perspective on this journey. This paradox posits that an infinite number of steps cannot be completed in a finite amount of time. It illustrates the conundrum of reaching a destination by constantly halving the distance, theoretically never arriving.

However, this paradox isn't just a philosophical musing; it holds valuable insights for modern product development. The essence of the paradox lies in the contrast between the finite and the infinite. It teaches us about the pitfalls of linear progression and the power of adopting a mindset focused on direction and velocity.

#### Setting a Direction, Not Just a Destination

In product development, defining a destination or a fixed target is the usual starting point. However, the Dichotomy Paradox warns us about the limitations of this approach. If we become overly fixated on the target, breaking down the journey into smaller and smaller linear segments, we risk never truly arriving. This is akin to the paradox's endless halving of distance, where the destination seems perpetually out of reach.

Instead, we advocate for a shift in perspective - from a fixed destination to a direction with a consistent velocity. This approach is akin to setting a course with a known speed, ensuring that the destination is not just approached but surpassed in due time.

#### Velocity Over Fixed Milestones

Our consultancy philosophy is deeply rooted in lean thinking. We understand that maintaining a consistent velocity, achieved through the elimination of waste and inefficiencies, is crucial. This approach shifts the focus from dissecting the path into minute steps to moving efficiently and effectively towards the broader goal. 

In our work, be it the development of platforms or the integration of complex systems, we prioritize a steady pace over rigid milestones. This methodology allows for flexibility, adaptability, and, most importantly, a consistent progression towards and beyond the intended outcome.

#### Practical Application in Product Development

Consider the development of an application or a system. A fixed, detailed plan may seem reassuring, but it often leads to an infinite subdivision of tasks, mirroring Zeno’s infinite halving of distance. By adopting a lean approach, focused on velocity and direction, we empower teams to move beyond the constraints of rigid planning. This strategy ensures not only the achievement of the initial goal but also the agility to adapt and exceed expectations.

#### Conclusion

Zeno’s Dichotomy Paradox, far from being a mere philosophical riddle, offers profound insights into the art of product development. It teaches us that the key to success is not in setting a rigid destination but in maintaining a clear direction and steady velocity. This approach has been instrumental in our ability to deliver innovative solutions that not only meet but often exceed our clients' expectations.

Let us help you navigate the path of product development, not by inching towards a distant target, but by moving swiftly and surely in the right direction.
`,
    author: "Phil Hauser",
    date: "2023-11-02",
    image: "/images/dichotomy.png",
  },
  {
    author: "Mike Smyth",
    date: "2023-10-14",
    image: "/images/IMG_0428.jpeg",
    content: `
# Rethinking Quality in Software Development: Moving Beyond Pull Requests

In the ever-evolving landscape of software development, the quest for quality is paramount. However, conventional practices like relying heavily on pull requests for quality control (QC) may not be the most effective approach. Instead, a shift towards Quality Assurance (QA) principles offers a more holistic and efficient way to ensure software quality.

## Pull Requests: A QC Approach with Limitations

Pull requests are a standard practice in software engineering, often used as a gatekeeping step before code merges into the main branch. They typically involve manual code reviews by team members, focusing on catching bugs, ensuring coding standards, and verifying functionality. While this practice has its merits, it essentially represents a QC step - a final checkpoint where errors are identified and corrected.

However, this QC approach has its drawbacks. It can be time-consuming, relies heavily on human effort, and often occurs too late in the development process. Issues identified at this stage can lead to significant rework, delays, and can even impact team morale.

## Embracing Quality Assurance in Software Engineering

In contrast to QC, QA in software engineering is about integrating quality checks throughout the development process, not just at the end. This approach aligns with the principles seen in modern manufacturing, where quality is built into the product at every step, rather than being inspected in at the end.

### Integrating QA Techniques:

- **Linting and Static Analysis:** These tools should be used as the code is being written. They help in maintaining code quality standards and catching errors early, reducing the burden on manual code reviews.
- **Pair Programming:** This technique involves two engineers working together on the same code. It not only fosters collaboration but also ensures continuous code review and knowledge sharing.
- **Small, Incremental Changes:** Encouraging developers to make smaller, more manageable changes can lead to easier code reviews and quicker integration, reducing the chances of significant errors.
- **Automated End-to-End Tests:** Implementing automated tests in CI/CD pipelines ensures that each code commit is tested in an environment that closely mirrors production, catching issues early.

## The Benefits of a QA-Focused Approach

- **Early Error Detection:** By integrating quality checks throughout the development process, issues are identified and resolved earlier, reducing the need for extensive rework.
- **Increased Efficiency:** Automated tools and practices like pair programming can significantly speed up the development process and reduce the time spent on manual reviews.
- **Better Code Health:** Continuous attention to quality leads to better overall code health and maintainability.
- **Enhanced Team Morale:** Developers feel more empowered and less burdened by the prospect of extensive pull request reviews, leading to higher job satisfaction and productivity.

## Conclusion

While pull requests and manual code reviews will always have a place in software development, they should not be the sole guardians of quality. By adopting QA principles and integrating quality checks throughout the development process, we can build software that is not only functional but also robust and maintainable. It's time we shift our focus from merely inspecting quality at the end to assuring quality at every step of our software development journey.
    `,
    id: 3,
    title: "QA vs QC and the Pull Request",
  },
  {
    id: 4,
    title: "Why Stage Zero?",
    author: "Mike Smyth",
    date: "2023-09-06",
    content: `
# Stage Zero: Elon Musk's Approach and Its Synergy with Lean Software Development

In the world of innovation and technology, Elon Musk is a name synonymous with groundbreaking ideas and transformative approaches. One such idea, often overlooked yet pivotal, is the concept of "Stage Zero." This concept, while simple in theory, holds profound implications for lean software development.

## Understanding Stage Zero

Elon Musk, in his pursuit of efficiency and effectiveness, emphasizes the importance of 'Stage Zero.' In essence, Stage Zero is about questioning the necessity of a task or process before it even begins. It's about asking, "Does this need to be done at all?" This preemptive questioning is not just about saving time and resources; it's about channeling them where they are most impactful.

## Stage Zero in Lean Software Development

Lean software development, with its roots in Lean manufacturing principles, focuses on maximizing value while minimizing waste. It's about delivering quality software efficiently and effectively. The integration of Stage Zero into this paradigm can lead to a more profound application of Lean principles. 

### Challenging Assumptions:

Before starting any development task, Stage Zero prompts us to challenge our assumptions. Are we building a feature because it's genuinely needed, or simply because it was in a plan? This step aligns perfectly with the Lean principle of 'eliminating waste' – in this case, the waste of effort on unnecessary tasks.

### Prioritizing Value:

Stage Zero also reinforces the Lean concept of 'delivering value.' By ensuring that every task undertaken is necessary, we inherently focus on work that directly contributes to creating value for the end-user.

### Streamlining Processes:

In Lean development, the focus is on streamlining processes and improving flow. Stage Zero contributes to this by preventing the clogging of pipelines with non-essential tasks. This step ensures that the team's attention remains on activities that genuinely push the project forward.

## Practical Application: A Case Study

Imagine a software team set to build a new feature. Traditional methods would jump straight into design and coding. However, applying Stage Zero, the team first assesses whether this feature is truly needed or if existing functionalities adequately meet user needs. This evaluation might lead to a decision not to proceed with development, thus saving time and resources that can be better utilized elsewhere.

## The Bigger Picture

The concept of Stage Zero is not just about individual tasks; it's about strategic thinking. It encourages teams and organizations to constantly reevaluate their priorities and focus on what's truly important. This strategic approach dovetails with Lean development's emphasis on creating more value with less work.

## Conclusion

Elon Musk's Stage Zero is a potent concept that can significantly enhance the principles of Lean software development. By integrating this concept, development teams can ensure that their efforts are not just efficient, but also effectively aligned with their ultimate goal – delivering maximum value to the user. In an industry where complexity and feature-creep are common pitfalls, Stage Zero serves as a vital checkpoint to keep projects lean, relevant, and impactful.
`,
    image: "/images/IMG_0431.jpeg",
  },
  {
    id: 5,
    title: "Build E2E first",
    author: "Phil Hauser",
    date: "2023-12-22",
    content: `
# Build E2E first

One of the many philosophies we embody at Stage Zero is to build things E2E first. When considering a strictly lean approach, the first principle is to be able to deliver value to customers, but have feedback from customers so that they can define what it is that’s valuable to them. 

Therefore, in order to start collecting feedback and guiding product development you _need_ to get something deployed E2E. 

For example, if we are tasked with building a news article page from an existing CMS, the first thing we should do is fetch the text from the api and shove it into a view and put that in front of users. Not many users, but enough to get meaningful feedback on whether what we have build is useful and effective. 

This simple technique also has the benefit of flushing out many of the technical issues you may encounter deploying code into a production environment and should help focus on building in feedback mechanisms to help inform the next steps. 

Simply put, probably the easiest first lean thinking step you could take is to ask yourself 2 questions:

- What’s the most valuable thing I can ship E2E
- How do I know if my assumption of value were correct

With these two questions you should begin to build in the foundation of a lean approach that will be user centred and delivery focused. 
    `,
    image: "/images/IMG_0548.jpeg",
  },
  {
    id: 6,
    title: "Announcing, Trust Zero",
    author: "Phil Hauser",
    date: "2024-5-02",
    content: `
# Trust Zero (Alpha): Building a more secure auth experience for the future

We are thrilled to announce the alpha release of [Trust Zero](https://trust-zero.uk/), a cutting-edge auth tool designed to revolutionize how businesses manage and secure their digital environments. At Stage Zero, we understand the challenges and complexities that come with digital security, and we are committed to providing solutions that are not only effective but also accessible.

## What is Trust Zero?

Trust Zero is a robust SaaS platform that offers a comprehensive suite of security features tailored to protect your business’s digital assets. From real-time threat detection to automated policy enforcement and compliance checks, Trust Zero ensures that your digital environment is secure, compliant, and resilient against evolving cyber threats.

## Key Features

- **Cryptographically secured out of the box**: We use the latest in auth standards to cryptographically secure your applications and make password hacking a thing of the past
- **Auth tags:** Simplify authorization with **Auth Tags**. Auth Tags allow you to flexibly create your own policies to assert against
- **Seamless Integration:** Easily integrate with your existing apps, just redirect users to your Trust Zero domain and wait for the response
- **User-Friendly Dashboard:** Monitor your security status through a clear, intuitive dashboard that gives you control and insight into your digital environment.

## Massive Free Tier Available!

To ensure that Trust Zero is accessible to all users, we are excited to offer a massive free tier. This is an excellent opportunity for businesses of all sizes to experience the advanced capabilities of Trust Zero without any initial investment.

- **Access to all core features**
- **No credit card required for sign-up**
- **Upgrade options as your business grows**

## Join the Alpha Release

This is just the beginning! By joining our alpha release, you will be at the forefront of digital security innovation. Your feedback will play a crucial

## What's next?

- AI threat detection
- Customized login pages
- Client libraries

`,
    image: "/images/tz.png",
  },
  {
    id: 7,
    title: "",
    author: "Phil Hauser",
    date: "2024-5-20",
    content: `
# Component libraries or design systems?

**Framing**: Most organizations I've worked in have had different concepts of where the lines are drawn between a design system and a component library.

There are many approaches and patterns, and deciding where responsibility lies differs in every organization.

To begin, allow me to define my terms:

- **Design System**: A common set of tokens and principles by which UI should adhere.
- **Component Library**: A coded implementation of a design system, usable for creating experiences that adhere to the design system for rapid application development.

The intention is to enable engineering to rapidly build out UI while maintaining consistency and aesthetic alignment.

However, to me, the component library is an implementation detail. I define an implementation detail as _anything that, if changed, wouldn’t be noticed by the user_.

Ultimately, users don't care if we use React, Vue, or Angular. However, if we were to alter our button colors, then users would almost certainly notice.

I appreciate I'm probably telling you something you already know, but I feel it's important to define my terms before digging into my thoughts.

An obvious but initial statement is that I believe these are two different things; you could have many component libraries that adhere to a design system, but under what circumstances do you begin to mandate a 1:1 application of component library to design system, and what are the trade-offs? 

![Image](https://i.imgur.com/Mu2kfLH.jpeg)

The most common situation I've found is the orange quadrants, with the green and red being rare cases.

So, _if_ the green quadrant is the target, does maintaining one source of truth in an implementation detail achieve that?

There are a few points I'd like to cover here. The first is around Brooks' Law and consensus building:

**Brooks' Law** 
![Image](https://i.imgur.com/Hvt4fLC.jpeg)
Simply put, the issue highlighted by Brooks is that the more members in your team, the more communication channels \`(team size - 1) x (team size / 2)\`. So with a single component library, we could end up with tens of communication channels.

You can think of these communication lines as a dependency graph. All the various teams using that component need a way to protect their UI from breaking.

Without some strict principles, the march to unification and reducing duplication can slow down delivery as you seek approval from all the various stakeholders. This then becomes a gradually declining system, which, if not course-corrected, will lead to something much scarier than duplication.

**Strategic reliance on implementation detail**

This, to me, is a big no-no. Strategy should be goal-setting at a higher level than the specifics of what code engineers author.

If you agree that a) a component library is an implementation detail, and b) strategy should be at a higher level than on an engineer's keyboard, then I believe naturally you'd agree that there shouldn't be a strategic drive towards a single component library implementation.

So then, instead of assessing the strategic importance of a single component library, we should look at how we end up in that green quadrant in the diagram above. How can we achieve a high level of design alignment and a rapid pace of delivery?

Perhaps a good way of looking at it is to assess what slows us down when utilizing a component library?

It becomes a problem of cyclomatic complexity; the more use cases a component needs to facilitate, the higher the complexity. For example, let's consider a simple dropdown component.

You could be deceived into thinking this component is fundamentally simple, with just 2 main states: open and closed. However, there are also none selected, one selected, multiple selected states as well as focus and blur states. Then, if we take this example and then need to add some unique functionality for a specific valid use case, the question becomes: how do we balance the risk and reward of making the change?

The mere fact that we are asking that question means that we've slowed down. We've moved instantly from the green quadrant to the orange. Our decision should be simple; in an ideal world, I should be able to address my use case faster with the component library than without, and if I can't, I shouldn't be using it.

You can't collapse the future potential into a decision now; you have to address the problem in front of you, which is: slow down or duplicate some code.

It seems to me that the simplest solution is to have a policy of only minimal use case support in a shared library. Anything else is a unique component build just for its specific use case.

Although you may be duplicating code, realistically that doesn't matter. If you consider the duplicated code as already existing within the cyclomatic complexity of the single component, just wrapped up in the conditionals and configurations.

**Options for practically maintaining aesthetic alignment and a high cadence**

In a word, Tailwind. I have found that Tailwind, as an example solution, allows me to codify a design system into a single configuration that can be independently updated, allowing me to rapidly build and prototype components. Whether they are shared or not is of little importance in this world, as the thing of significant importance is that we have strong alignment with a design system which can be expressed within a single configuration.

Even in the abstract, that seems to be a closer analogy to what we want: a design system that is codified to ensure consistency and alignment, whilst not being so prescriptive in terms of code and components that we then get significantly slowed down.
`,
    image: "https://i.imgur.com/QZDikYZ.jpeg",
  },
];
