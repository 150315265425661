import { MyParallax } from "components/parallax";
import { Card } from "components/card";
import image from "images/background.png";
import image2 from "images/background-sphere.png";
import { blogPosts } from "data/blog";
import { caseStudies } from "data/case-studies";
import { BlogPost } from "components/blog-summary";
import { useState } from "react";
import { ContentModal } from "components/content-modal";
import { CaseStudy } from "components/case-study-summary";

function App() {
  const [selectedContent, setSelectedContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePostClick = (content) => {
    setSelectedContent(content);
    setIsModalOpen(true);
  };

  return (
    <div className={`App h-full ${isModalOpen && `overflow-hidden`}`}>
      <header className="text-center absolute top-0 left-0 right-0 z-50 bg-transparent p-4">
        <nav className="flex justify-between items-center">
          <h1 className="text-2xl md:text-4xl text-white font-bold">
            Stage Zero
          </h1>
          <a
            href="mailto:info@stagezero.uk?subject=Enquiry&body=Hi%20Stage%20Zero%0D%0A%0D%0AWe%20love%20the%20idea%20of%20upping%20our%20Lean%20Thinking%20game%20and%20have%20some%20exciting%20products%20planned.%20We'd%20love%20your%20help%20getting%20them%20to%20market!%20You%20can%20reach%20us%20at%3A%0D%0A%0D%0AEmail%3A%0D%0APhone%3A%0D%0A%0D%0ARegards%2C%0D%0A%0D%0A%3A)"
            className="text-white bg-blue-500 hover:bg-blue-700 font-medium py-2 px-4 rounded-full"
          >
            Contact Us
          </a>
        </nav>
      </header>

      <MyParallax image={image2}>
        <div className="flex flex-col md:flex-col items-center justify-center mt-[15vh]">
          <h2 className="text-2xl md:text-4xl text-white w-full text-center py-8 px-4">
            You can't launch 🚀 without considering Stage Zero
          </h2>
          <h3 className="text-white text-2xl px-4 text-center">
            AI enabled Lean transformation, delivering:
          </h3>
          <div className="flex flex-col md:flex-row mb-12">
            <Card
              className="backdrop-blur-lg w-[80vw] md:w-[30vw] text-white max-w-sm mx-2 md:mx-4 my-4 md:w-1/3"
              title="Lean Principles"
              content="Focusing purely on value delivery"
            />
            <Card
              className="backdrop-blur-lg w-[80vw] md:w-[30vw] text-white max-w-sm mx-2 md:mx-4 my-4 md:w-1/3"
              title="Training"
              content="Help you build a culture of Lean Principles"
            />
            <Card
              className="backdrop-blur-lg w-[80vw] md:w-[30vw] text-white max-w-sm mx-2 md:mx-4 my-4 md:w-1/3"
              title="Accelerated Delivery"
              content="Get feedback, fast"
            />
          </div>
        </div>
      </MyParallax>

      <section className="my-12 px-4 md:px-12">
        <h2 className="text-2xl md:text-3xl font-medium md:text-center font-['Space_Mono']">
          Our Engagement Process
        </h2>
        <div className="bg-white p-8">
          <div className="flex justify-center">
            <div className="grid md:grid-cols-2 gap-6 max-w-[1000px]">
              <div className="flex items-start">
                <div className="text-4xl mr-4">🔍</div>
                <div>
                  <h3 className="font-semibold text-xl">
                    Strategic and Tactical Lean Transformation
                  </h3>
                  <p>
                    We integrate into every level, guiding the entire software
                    team into a leaner place.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="text-4xl mr-4">🤝</div>
                <div>
                  <h3 className="font-semibold text-xl">Support and Adapt</h3>
                  <p>
                    Support your team with adapting lean practices tailored to
                    your business.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="text-4xl mr-4">🎯</div>
                <div>
                  <h3 className="font-semibold text-xl">Focus on User Value</h3>
                  <p>
                    Refocus your product teams on defining and measuring user
                    value, amplifying your ability to succeed.
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="text-4xl mr-4">🚀</div>
                <div>
                  <h3 className="font-semibold text-xl">Deliver MVP</h3>
                  <p>
                    Lead the team in delivering a Minimum Viable Product with
                    full support.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MyParallax image={image}>
        <section className="p-8">
          <h2 className="text-2xl md:text-3xl font-medium font-['Space_Mono'] md:text-center text-white">
            Case Studies
          </h2>
          <div className="flex justify-center">
            <div className="grid md:grid-cols-2 gap-6 max-w-[1000px]">
              {caseStudies.map((study) => {
                return (
                  <div className="flex justify-center ">
                    <CaseStudy
                      key={"case-study" + study.id}
                      {...study}
                      onClick={() => handlePostClick(study)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </MyParallax>

      <section className="my-12 px-4 md:px-12">
        <h2 className="text-2xl md:text-3xl font-medium font-['Space_Mono'] md:text-center">
          Latest Insights
        </h2>
        <div className="flex justify-center">
          <div className="grid md:grid-cols-2 gap-6 max-w-[1000px]">
            {blogPosts.sort((a, b) => b.date.localeCompare(a.date)).map((post) => {
              return (
                <div className="flex justify-center ">
                  <BlogPost
                    key={"post" + post.id}
                    {...post}
                    onClick={() => handlePostClick(post)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <footer className="bg-gray-800 text-white text-center p-4">
        <p>
          &copy; {new Date().getFullYear()} Stage Zero. All rights reserved.
        </p>
      </footer>

      <ContentModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        post={selectedContent}
      />
    </div>
  );
}

export default App;
