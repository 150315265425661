export const BlogPost = ({ title, author, date, onClick, image }) => (
  <article className="max-w-md m-4 overflow-hidden rounded-lg shadow-lg">
    <button onClick={onClick}>
      <div className="h-48 overflow-hidden">
        <img alt="Placeholder" className="block h-auto w-full" src={image} />
      </div>

      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
        <h1 className="text-lg">
          <p className="no-underline hover:underline text-black text-left">
            {title}
          </p>
        </h1>
        <div className="w-[120px]">
          <p className="ml-2 text-sm">{author}</p>
          <p className="text-grey-darker text-sm">{date}</p>
        </div>
      </header>
    </button>
  </article>
);
