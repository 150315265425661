export const caseStudies = [
  {
    id: 1,
    title: "Disney+",
    content: `
# Case Study: Fostering Collaboration and Innovation in Building Critical Tooling for Disney+

## Overview

**Client:** Disney+  
**Project Duration:** 6 Months  
**Key Deliverables:** A/B Testing Platform and Other Critical Internal Tools  
**Approach:** Continuous Deployment, Extreme Programming Principles, High Collaboration  
**Objective:** To develop critical internal tooling for Disney+ while fostering a strong collaborative culture and educating the business on advanced software development practices.

## Challenge

Disney+, aiming to enhance its streaming service, required sophisticated internal tooling, including an A/B testing platform, within a tight timeline of six months. The project's success hinged on high collaboration, rapid deployment, and effective incident management. Additionally, there was a need to educate the wider business on these practices to ensure broad adoption and understanding.

## Solution

### Continuous Deployment into Production:
- We implemented Continuous Deployment (CD) practices, ensuring that every change went through a pipeline of automated tests before being deployed to production. This approach accelerated the development process and minimized errors, making our deliverables more reliable and efficient.

### Embracing Extreme Programming (XP) Principles:
- Our team adopted XP principles, which emphasized customer satisfaction, simplicity, continuous feedback, and courage. These principles were key in maintaining high-quality standards and adapting quickly to changing requirements.

### High Collaboration and Evangelism:
- **Workshops and Training:** We conducted workshops to evangelize the importance of high collaboration, solid Continuous Integration (CI), and low Mean Time to Resolution (MTTR). These sessions focused on hands-on experiences and real-world scenarios to maximize learning and engagement.
- **Internal Blogs and Talks:** Our team members actively wrote internal blogs and delivered talks, sharing insights, best practices, and lessons learned from our project. This initiative helped in spreading awareness and understanding of our methodologies across Disney+.

### Educating the Business:
- We held regular sessions with various business teams to educate them about our development practices and the benefits they bring. These sessions were instrumental in aligning technical and business perspectives, ensuring everyone understood the value of our approaches.

## Results

- **Successful Deployment:** Our team successfully delivered the A/B testing platform and other critical internal tools within the ambitious six-month timeframe.
- **Cultural Shift:** Our efforts in evangelizing high collaboration and advanced software practices contributed to a cultural shift within Disney+. Teams became more open to embracing new methodologies and working closely together.
- **Enhanced Business Understanding:** The internal blogs, talks, and workshops significantly increased the wider business’s understanding of our development practices, leading to broader acceptance and support.
- **Improved Agility and Resilience:** The adoption of CD and XP principles improved the team's agility and the resilience of the systems we developed, allowing Disney+ to adapt more rapidly to market changes.

## Conclusion

This project showcases how a focus on fostering a collaborative culture and educating the business on advanced development practices can drive significant improvements in software development processes. Our team’s efforts at Disney+ not only resulted in the successful delivery of critical tooling but also created a lasting impact on how the organization approaches software development, paving the way for continuous innovation and improvement.
`,
    image: "/images/disney.jpeg",
  },
  {
    id: 2,
    title: "Pentatonic",
    image: "/images/pentatonic.png",
    content: `
# Case Study: Accelerating Pentatonic's Digital Transformation with Rapid MVP Development

## Overview

**Client:** Pentatonic  
**Project Duration:** 3 Months  
**Key Deliverables:** AI Platform, CRM, Supply Chain Cross-Platform App and Dashboard  
**Approach:** Continuous Integration/Continuous Deployment (CI/CD) First, Rapid MVP Development  
**Objective:** To enable quick market validation of Pentatonic’s innovative ideas through the development of multiple complex tools.

## Challenge

Pentatonic, a forward-thinking company, sought to revolutionize its digital capabilities within an ambitious timeframe of just three months. They needed a comprehensive suite of tools including an AI platform, a Customer Relationship Management (CRM) system, and a supply chain cross-platform application with an integrated dashboard. The critical challenge was to develop these complex tools rapidly while ensuring they were robust, scalable, and could provide immediate market validation for Pentatonic's innovative ideas.

## Solution

### Emphasis on CI/CD:
We commenced the project with a strong focus on establishing a robust CI/CD pipeline. This approach ensured a seamless and efficient development process, enabling frequent and reliable code integration and deployment. By automating the build, test, and deployment phases, we significantly reduced manual errors and increased development speed.

### Rapid MVP Development:
Our strategy revolved around quickly developing Minimum Viable Products (MVPs) for each tool. This approach allowed Pentatonic to test and validate their ideas in the market without the need for fully fleshed-out products. Key steps in our approach included:

- **Iterative Development:** Adopting an iterative development approach allowed us to build, test, and refine the products in cycles, ensuring continuous improvement based on user feedback and performance metrics.
- **Modular Architecture:** We designed the tools with modular architectures, enabling us to develop and deploy features independently and efficiently.
- **User-Centric Design:** Keeping the end-user in mind, we designed intuitive and user-friendly interfaces for each tool, enhancing user engagement and satisfaction.

### Collaborative Effort:
Our team worked closely with Pentatonic’s stakeholders to understand their vision and requirements. This collaboration was instrumental in aligning our development efforts with their business objectives and user needs.

## Results

- **Successful Deployment:** We successfully developed and deployed the AI platform, CRM system, and supply chain app with an integrated dashboard within the ambitious three-month timeframe.
- **Market Validation:** The MVPs allowed Pentatonic to quickly obtain market feedback, helping them make informed decisions about future product developments and investments.
- **Scalability and Performance:** The tools were built to be scalable and high-performing, capable of handling increasing loads and evolving business requirements.

## Conclusion

This project exemplifies how focusing on CI/CD and rapid MVP development can significantly accelerate digital transformation. Our approach enabled Pentatonic to swiftly bring their ideas to market, gather valuable insights, and lay a strong foundation for future innovations. The success of this project has not only empowered Pentatonic in their immediate objectives but also positioned them as a leader in leveraging technology for business growth and innovation.

`,
  },
];
