export const CaseStudy = ({ title, onClick, image }) => (
  <article className="max-w-md m-4 relative">
    <button onClick={onClick} className="overflow-hidden rounded-lg shadow-lg">
      <img alt="Placeholder" className="w-full" src={image} />

      <header className="text-left p-2 md:p-4 absolute top-0 bg-gradient-to-b from-black to-transparent w-full h-1/2 overflow-hidden rounded-lg">
        <h1 className="text-lg">
          <p className="no-underline hover:underline text-white">{title}</p>
        </h1>
      </header>
    </button>
  </article>
);
