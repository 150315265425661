export const Card = ({ title, content, className = "" }) => (
  <div
    className={`max-w-sm rounded-2xl overflow-hidden shadow-lg m-4 font-[Space_Mono] ${className}`}
  >
    <div className="px-4 py-2 md:px-6 md:py-4">
      <div className="font-bold text-md md:text-xl mb-2">{title}</div>
      <p className="text-base text-sm md:text-md">{content}</p>
    </div>
  </div>
);
