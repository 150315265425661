import Markdown from "react-markdown";
// BlogModal.js
export const ContentModal = ({ isOpen, onClose, post }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed overflow-scroll inset-0 bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-4 rounded max-w-[700px] w-full md:m-8 md:mt-[100px] md:left-[calc(50%-350px)]">
        <img
          src={post.image}
          alt={post.title}
          className="w-[calc(100%+32px)] relative -ml-4 -mt-4"
        />
        <button
          onClick={onClose}
          className="absolute top-0 right-[14px] rounded-full bg-[rgba(0,0,0,0.7)] text-white h-[48px] w-[48px] m-2"
        >
          <span className="text-[48px] relative -top-[14px]">×</span>
        </button>
        <Markdown
          className="space-y-[12px]"
          components={{
            h1({ children }) {
              return <h2 className="text-4xl py-4">{children}</h2>;
            },
            h2({ children }) {
              return <h2 className="text-2xl pt-4">{children}</h2>;
            },
            h3({ children }) {
              return <h2 className="text-2xl pt-4">{children}</h2>;
            },
            h4({ children }) {
              return <h4 className="pt-4 text-xl">{children}</h4>;
            },
            img({ src }) {
              return (
                <div className="w-full pb-2">
                  <img alt="blog" className="w-full" src={src} />
                </div>
              );
            },
          }}
        >
          {post.content}
        </Markdown>
      </div>
    </div>
  );
};
